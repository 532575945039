import React, { useState } from 'react'
import styled from 'styled-components'
import Flags from 'country-flag-icons/react/3x2'
import { BiMap, BiPhone, BiGlobe } from "react-icons/bi";

const CompanyContacts = ({ contacts, country }) => {

  const { companyAddress, companyPhone, companySite, companyTiker } = contacts
  const [copySuccess, setCopySuccess] = useState('Копировать тикер')
  const [styleSuccess, setStyleSuccess] = useState('')

  const copyToClipboard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setCopySuccess('Тикер скопирован')
      setStyleSuccess('success')
    }
    catch (err) {
      setCopySuccess('Копирование не удалось')
      setStyleSuccess('error')
    }
  }

  const getFlag = cntr => {
    switch (cntr) {
      case 'США':
        return <Flags.US title={ cntr } className="CompanyContacts__item_flag" />
        break;

      case 'Россия':
        return <Flags.RU title={ cntr } className="CompanyContacts__item_flag" />
        break;

      default:
        break;
    }
  }

  return (
    <CompanyContactsBlock>
      <li className="CompanyContacts__item_ticker">
        <p>{ companyTiker }</p>
        <button className={ styleSuccess } onClick={() => copyToClipboard(companyTiker)}>{ copySuccess }</button>
      </li>
      <li className="CompanyContacts__item_country">
        { getFlag(country) }
        <span>{ country }</span>
      </li>
      <li className="CompanyContacts__item_adress">
        <span className="icon"><BiMap /></span>
        <span>{ companyAddress }</span>
       </li>
      <li className="CompanyContacts__item_phone">
        <span className="icon"><BiPhone /></span>
        <span>{ companyPhone }</span>
       </li>
      <li className="CompanyContacts__item_site">
        <span className="icon"><BiGlobe /></span>
        <span>{ companySite }</span>
      </li>
    </CompanyContactsBlock>
  )
}

export default CompanyContacts

const CompanyContactsBlock = styled.ul `

  li:not(.CompanyContacts__item_ticker) + li {
    border-top: 1px solid ${props => props.theme.grayLight};
  }

  .CompanyContacts__item_ticker {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2.4rem;

    @media only screen and (max-width: 780px) {
      /* flex-direction: column; */
      margin-bottom: 1rem;
    }

    p {
      display: inline-block;
      padding: 0 1.5rem;
      font-size: 2rem;
      font-weight: 700;
      border: 1px solid #bdd6e9;
      border-radius: 0.3rem;
      background-color: #deedf7;

      @media only screen and (max-width: 780px) {
        padding: 0 0.8rem;
        font-size: 1.6rem;
        text-align: center;
      }
    }

    button {
      margin-left: 1.5rem;
      padding-right: 1rem;
      padding-left: 1rem;
      border: 1px solid ${props => props.theme.grayLight};
      border-radius: 0.3rem;
      font-size: 1.2rem;
      color: #437ca7;
      background-color: ${props => props.theme.white};
      cursor: pointer;

      @media only screen and (max-width: 780px) {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
      }
    }

    .success {
      color: ${props => props.theme.black};
      border-color: ${props => props.theme.blue};
    }
  }

  .CompanyContacts__item_country {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1.4rem;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0;
  }

  .CompanyContacts__item_flag {
    margin-right: 1rem;
    width: 26px;
  }

  .CompanyContacts__item_adress,
  .CompanyContacts__item_phone,
  .CompanyContacts__item_site {
    display: flex;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    margin-top: 0;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.01em;
    color: ${props => props.theme.black};

    .icon {
      flex: 1 0 1;
      margin-right: 1rem;
      width: 22px;
      height: 22px;
      svg {
        width: 22px;
        height: 22px;
        fill: ${props => props.theme.blueGray};
      }
    }
  }

  .CompanyContacts__item_adress {
    font-size: 1.3rem;
  }

  .CompanyContacts__item_phone {
    align-items: center;
  }

  .CompanyContacts__item_site {
    align-items: center;
    padding-bottom: 0;
    color: ${props => props.theme.blueLink};
  }
`

import React from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../../components/Breadcrumbs'
import Img from '../../components/Img'

export const CompanyHero = ({ heroTitle, heroImage, heroCategory, breadcrumbs, heroTicker }) => {
  return (
    <CompanyHeroBlock>
      { heroImage && <Img styles="CompanyHero__img"
        localFile={ heroImage }
        alt={ `${heroTitle} | ${heroTicker}` }
      />}
      <div className="CompanyHero__content">
        <small className="CompanyHero__small">{ heroCategory }</small>
        <h1 className="CompanyHero__title">{ heroTitle }</h1>
        <Breadcrumbs breadcrumbs={ breadcrumbs } variant="company" />
      </div>
    </CompanyHeroBlock>
  )
}

export default CompanyHero

const CompanyHeroBlock = styled.section `
  display: flex;
  align-items: flex-start;
  padding: 3rem;
  margin-bottom: 2rem;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.shadowBlock};
  overflow: hidden;

  @media only screen and (max-width: 1440px) {
    padding: 2.6rem;
    border-radius: 0;
  }

  @media only screen and (max-width: 780px) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    padding-top: 3rem;
    padding-bottom: 2rem;
    align-items: center;
    text-align: center;
  }

  .CompanyHero__content {
    @media only screen and (max-width: 780px) {
      margin-left: auto;
      margin-right: auto;
      width: 94%;
    }
  }

  .CompanyHero__img {
    margin-right: 3rem;
    width: 150px;
    border-radius: 1rem;

    @media only screen and (max-width: 1080px) {
      width: 120px;
    }

    @media only screen and (max-width: 780px) {
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }

  .CompanyHero__title {
    font-size: 3.6rem;
    margin-bottom: 2rem;
    letter-spacing: 0;
    text-transform: uppercase;

    @media only screen and (max-width: 1080px) {
      margin-bottom: 1.6rem;
      font-size: 3.2rem;
    }

    @media only screen and (max-width: 480px) {
      font-size: 2.8rem;
    }
  }

  .CompanyHero__small {
    display: inline-block;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    background-color: ${props => props.theme.skyExtraLight};
    color: ${props => props.theme.blueGray};

    @media only screen and (max-width: 480px) {
      margin-bottom: 1rem;
    }
  }
`

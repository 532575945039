import React from 'react'
import { graphql, Link } from 'gatsby'
import parse from 'html-react-parser'
import { useCompaniesQuery } from '../hooks/useCompaniesQuery'
import Layout from '../components/Layout/Layout'
import Container from '../components/Container'
import CompanyHero from '../components/Company/CompanyHero'
import Company from '../components/Company/Company'
import CompanyContacts from '../components/Company/CompanyContacts'
import CompanySimilar from '../components/Company/CompanySimilar'
import SideBar from '../components/SideBar/SideBar'
import SideBarContent from '../components/SideBar/SideBarContent'


const CompanyTemplate = ({ pageContext, data }) => {

  const {
    id,
    title,
    content,
    seo,
    companyFields,
    featuredImage,
    sectors,
    countries } = data.allWpCompany.nodes[0]

  const comnanies = useCompaniesQuery().allWpCompany.nodes
  const similarCompanies = comnanies.filter(company => (
    company.sectors.nodes[0].name === sectors.nodes[0].name && company.id !== id
  ))

  return (
    <Layout seo={ seo } companyTicker={ companyFields.companyTiker }>
      <CompanyHero
        heroTitle={ title }
        heroCategory="О компании"
        breadcrumbs={ seo.breadcrumbs }
        heroImage={ featuredImage.node.localFile }
        heroTicker={ companyFields.companyTiker }
      />
      <Container variant="aside">
        <SideBar page="company">
          <SideBarContent>
            <CompanyContacts contacts={ companyFields } country={ countries.nodes[0].name } />
          </SideBarContent>
          <SideBarContent title="Компании из сектора" space='2rem'>
            <CompanySimilar similar={ similarCompanies } />
          </SideBarContent>
        </SideBar>
        <Company title="О компании" sector={ sectors.nodes[0] }>
          {/* <Link to={ pageContext.backPath }>Назад</Link> */}
          { content && parse(content) }
        </Company>
      </Container>
    </Layout>
  )
}

export default CompanyTemplate


export const postCompanyQuery = graphql `
  query CompanyQuery($slug: String) {
    allWpCompany(filter: {slug: {eq: $slug}}) {
      nodes {
        id
        slug
        title
        uri
        content
        companyFields {
          companyAddress
          companyPhone
          companySite
          companyTiker
        }
        sectors {
          nodes {
            name
          }
        }
        countries {
          nodes {
            name
          }
        }
        seo {
          canonical
          metaDesc
          opengraphDescription
          opengraphModifiedTime
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
          title
          breadcrumbs {
            text
            url
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, height: 400, placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`
import React from 'react'
import styled from 'styled-components'

const Company = ({ title, sector, country, children }) => {

  return (
    <CompanyBlock>
      <h4>{ title }</h4>
      <div className="Company__sector">
        <span>Сектор:</span>
        <strong>{ sector.name }</strong>
      </div>
      { children }
    </CompanyBlock>
  )
}

export default Company

const CompanyBlock = styled.div `
  flex: 1;
  max-width: 1180px;
  width: 60%;
  margin-left: 2rem;
  padding: 4rem;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.shadowBlock};

  @media only screen and (max-width: 1080px) {
    padding: 3rem;
  }

  @media only screen and (max-width: 980px) {
    padding: 2rem;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
  }

  .Company__sector {
    font-size: 1.4rem;
    letter-spacing: 0.02em;

    strong {
      margin-left: 1rem;
    }
  }
`

import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from '../Img'

const CompanySimilar = ({ similar }) => {

  return (
    <CompanySimilarBlock>
      { similar && similar.map(company => (
        <li className="CompanySimilar__item" key={ company.id }>
          <Link className="CompanySimilar__content" to={ company.uri }>
            <div>
              <div className="CompanySimilar__ticker">{ company.companyFields.companyTiker }</div>
              <div className="CompanySimilar__title">{ company.title }</div>
            </div>
            <Img
              styles="CompanySimilar__img"
              localFile={ company.featuredImage.node.localFile }
              alt={ `${company.title} | ${company.companyFields.companyTiker}` }
             />
          </Link>
        </li>
      ))}
    </CompanySimilarBlock>
  )
}

export default CompanySimilar

const CompanySimilarBlock = styled.ul `
  max-height: 300px;
  overflow: auto;

  @media only screen and (max-width: 480px) {
    max-height: 205px;
  }

  .CompanySimilar__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .CompanySimilar__item + .CompanySimilar__item {
    margin-top: 0;
    padding-top: 1rem;
    border-top: 1px solid ${props => props.theme.grayLight};
  }

  .CompanySimilar__img {
    flex: 1 0 100%;
    max-width: 30px;
    margin-left: 1rem;
    border-radius: 50%;
  }

  .CompanySimilar__item {
    padding-bottom: 0.9rem;

    &:hover {
      .CompanySimilar__ticker {
        color: ${props => props.theme.black};
      }

      .CompanySimilar__title {
        color: ${props => props.theme.gray};
      }
    }
  }

  .CompanySimilar__ticker {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.01em;
    color: ${props => props.theme.blue};
    transition: ${props => props.theme.transition};
  }

  .CompanySimilar__title {
    margin-top: 0.3rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0;
    color: ${props => props.theme.black};
    transition: all ease 0.2s;
  }
`
